<template>
  <g>
    <path d="M7,10.7h6.4l0-1.5l-4.9,0l0-4.9H7V10.7z" />
  </g>
</template>

<script>
/**
 * Icons are used to visually communicate core parts of the product and
 * available actions. Please be aware that all elements must have closing tags (not "self-closing").
 * To add additional icons, please consult [the instructions](/#/Adding%20Icons).
 */
export default {
  name: 'IconEndNode',
  status: 'ready',
  release: '1.0.0',
  type: 'Element'
}
</script>
